<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
// import { WOW } from "wowjs";
export default {
  data(){
    return{

    }
  },
  // mounted() {
  //   document.dispatchEvent(new Event('render-event'));
  // }
  // mounted() {
  //   new WOW({ live: false }).init();
  // },
}
</script>
<style lang="less">
html,body,#app {
  font-family: '微软雅黑', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  font-size: 16px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
}
p,span,h1,h2,h3,h4,h5,h6{
  margin: 0 !important;
  padding: 0 !important;
}
</style>
