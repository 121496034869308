import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect:'/home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "index" */ '../views/index.vue'),
    children:[
      {
        path: 'home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/home/home.vue'),
      },
      {
        path: 'about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/about/about.vue'),
      },
      // {
      //   path: 'case',
      //   name: 'case',
      //   component: () => import(/* webpackChunkName: "case" */ '../views/case/case.vue'),
      // },
      {
        path: 'dosingCase',
        name: 'dosingCase',
        component: () => import(/* webpackChunkName: "case" */ '../views/case/dosingCase.vue'),
      },
      {
        path: 'maintenanceCase',
        name: 'maintenanceCase',
        component: () => import(/* webpackChunkName: "case" */ '../views/case/maintenanceCase.vue'),
      },
      // {
      //   path: 'aeration',
      //   name: 'aeration',
      //   component: () => import(/* webpackChunkName: "case" */ '../views/case/aeration.vue'),
      // },
      {
        path: 'plc',
        name: 'plc',
        component: () => import(/* webpackChunkName: "case" */ '../views/case/plc.vue'),
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import(/* webpackChunkName: "contact" */ '../views/contact/contact.vue'),
      }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
